import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Notifications from "../views/Notifications.vue";
import PersonalNotification from "../views/PersonalNotification.vue";
import AdminOrder from "../views/AdminOrder.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/personal",
    name: "PersonalNotification",
    component: PersonalNotification,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/orders",
    name: "adminOrder",
    component: AdminOrder,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
