<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-container class="container sticky-header" fluid>
        <v-card elevation="0">
          <v-row style="margin: 10px">
            <v-col xs="12" sm="12" md="6" lg="3">
              <div style="text-align: center; padding: 20px">
                <v-avatar size="300" style="margin: 10px auto; border: 1px solid #000000">
                  <img :src="require('./../assets/logo3.png')" alt="الصورة الشخصية" v-if="$store.state.employeeDetails?.profileImage == null" />
                  <img :src="`${$store.state.apiUrlEmployee}/${$store.state.employeeDetails.profileImage}`" alt="الصورة الشخصية" v-else />
                </v-avatar>
                <div v-if="$store.state.employeeDetails?.profileImage == null">
                  <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClick">
                    <v-icon left> mdi-cloud-arrow-up </v-icon>
                    اضافة صورة شخصية
                  </v-btn>
                  <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
                </div>
                <div v-else>
                  <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClick">
                    <v-icon left> mdi-cloud-arrow-up </v-icon>
                    تعديل صورة شخصية
                  </v-btn>
                  <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
                </div>
              </div>

              <div style="padding: 0px 20px">
                <v-text-field outlined v-model="$store.state.employeeDetails.employeeName" label="الاسم"></v-text-field>
                <v-autocomplete v-model="$store.state.employeeDetails.sectionId" :items="sections" item-text="sectionName" item-value="idSection" outlined label="القسم"></v-autocomplete>
                <v-text-field outlined v-model="$store.state.employeeDetails.phone" label="رقم الهاتف"></v-text-field>
                <v-autocomplete v-model="$store.state.employeeDetails.gender" :items="$store.state.genders" item-text="name" item-value="name" outlined label="الجنس"></v-autocomplete>
                <v-text-field v-model="$store.state.employeeDetails.dobFormat" label="تاريخ الميلاد" outlined type="date"></v-text-field>
                <v-text-field outlined v-model="$store.state.employeeDetails.mail" label="البريد"></v-text-field>
                <v-autocomplete v-model="$store.state.employeeDetails.roleId" :items="roles" item-text="roleName" item-value="idRole" disabled outlined label="الصلاحيات"></v-autocomplete>
                <v-text-field outlined v-model="$store.state.employeeDetails.nationality" label="الجنسية"></v-text-field>
                <v-text-field outlined v-model="$store.state.employeeDetails.motherName" label="اسم الام"></v-text-field>
              </div>
              <v-btn class="ma-2" color="success" outlined @click="updateEmployee">
                <v-icon left> mdi-pencil </v-icon>
                تعديل المعلومات الشخصية
              </v-btn>
            </v-col>

            <v-divider vertical></v-divider>
            <v-col xs="12" sm="12" md="6" lg="9">
              <div style="height: 20px"></div>
              <v-alert border="left" color="label"> <h3 style="color: #ffffff">المعلومات الاكاديمية</h3> </v-alert>
              <div style="height: 20px"></div>

              <v-row>
                <v-col cols="6" style="padding: 10px">
                  <v-autocomplete
                    v-model="$store.state.employeeDetails.certificateId"
                    :items="certificates"
                    item-text="certificateName"
                    item-value="idCertificate"
                    outlined
                    label="نوع الشهادة"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="$store.state.employeeDetails.academicTitleId"
                    :items="academicTitles"
                    item-text="title"
                    item-value="idAcademicTitle"
                    outlined
                    label="العنوان الوظيفي"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" style="padding: 10px">
                  <v-text-field outlined v-model="$store.state.employeeDetails.certificateCountry" label="بلد اصدار الشهادة"></v-text-field>
                  <v-text-field outlined v-model="$store.state.employeeDetails.specialist" label="التخصص"></v-text-field>
                </v-col>
              </v-row>

              <div style="height: 20px"></div>
              <v-alert border="left" color="label"> <h3 style="color: #ffffff">معلومات السكن</h3> </v-alert>
              <div style="height: 20px"></div>
              <v-row>
                <v-col cols="6" style="padding: 10px">
                  <v-autocomplete v-model="$store.state.employeeDetails.provinceId" :items="provinces" item-text="provinceName" item-value="idProvince" outlined label="المحافظة"></v-autocomplete>

                  <v-text-field outlined v-model="$store.state.employeeDetails.alley" label="المحلة"></v-text-field>
                  <v-text-field outlined v-model="$store.state.employeeDetails.houseNumber" label="رقم الدار"></v-text-field>
                </v-col>
                <v-col cols="6" style="padding: 10px">
                  <v-text-field outlined v-model="$store.state.employeeDetails.city" label="المنطقة"></v-text-field>
                  <v-text-field outlined v-model="$store.state.employeeDetails.avenue" label="الزقاق"></v-text-field>
                </v-col>
              </v-row>

              <div style="height: 20px"></div>
              <v-alert border="left" color="label"> <h3 style="color: #ffffff">معلومات اضافية</h3> </v-alert>
              <div style="height: 20px"></div>
              <v-row>
                <v-col cols="6" style="padding: 10px">
                  <v-text-field outlined v-model="$store.state.employeeDetails.cardNumber" label="رقم البطاقة"></v-text-field>
                  <v-text-field outlined v-model="$store.state.employeeDetails.studyType" label="وقت العمل"></v-text-field>
                </v-col>
                <v-col cols="6" style="padding: 10px">
                  <v-text-field outlined v-model="$store.state.employeeDetails.passportNumber" label="رقم الجواز"></v-text-field>
                  <v-autocomplete
                    v-model="$store.state.employeeDetails.idContactType"
                    :items="contractTypes"
                    item-text="typeName"
                    item-value="idContactType"
                    outlined
                    label="نوع العقد"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-textarea rows="3" row-height="15" outlined v-model="$store.state.employeeDetails.notes" name="input-7-4" label="الملاحظات"></v-textarea>

              <v-btn class="ma-2" color="success" outlined @click="updateEmployeeInformation">
                <v-icon left> mdi-pencil </v-icon>
                تعديل المعلومات
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    update: false,
    overlay: false,
    showUpload: true,

    selectedFile: null,
    isSelecting: false,
    academicTitles: [],
    provinces: [],
    contractTypes: [],
    certificates: [],
    roles: [],
    sections: [],
    photoPath: "",
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlEmployee}/provinces`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/contractTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/certificates`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/academicTitles`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/roles`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegister}/allSections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlEmployee}/employee/${this.$store.state.userData.idEmployee}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.user}`,
            },
          }),
        ])
        .then(
          self.$http.spread((provinces, contractTypes, certificates, academicTitles, roles, sections, employee) => {
            self.provinces = provinces.data;
            self.contractTypes = contractTypes.data;
            self.certificates = certificates.data;
            self.academicTitles = academicTitles.data;
            self.roles = roles.data;
            self.sections = sections.data;
            self.$store.state.employeeDetails = employee.data;
            console.log(roles.data);
            self.overlay = false;
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    updateEmployeeInformation() {
      let self = this;
      self.overlay = true;
      if (self.$store.state.employeeDetails?.idEmployeeInformation) {
        self.$http
          .put(`${self.$store.state.apiUrlEmployee}/employeeInformation/${self.$store.state.employeeDetails.idEmployeeInformation}`, self.$store.state.employeeDetails, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((resImageUpdate) => {
            self.overlay = false;
            console.log(resImageUpdate);
            self.initialData();
            this.$toasted.success("تم رفع خزن البيانات");
          })
          .catch((error) => {
            self.overlay = false;
            console.log(error);
            this.$toasted.error("حدث خطأ في رفع الصورة");
          });
      } else {
        let data = {
          academicTitleId: self.$store.state.employeeDetails.academicTitleId,
          certificateCountry: self.$store.state.employeeDetails.certificateCountry,
          certificateId: self.$store.state.employeeDetails.certificateId,
          specialist: self.$store.state.employeeDetails.specialist,
          provinceId: self.$store.state.employeeDetails.provinceId,
          city: self.$store.state.employeeDetails.city,
          avenue: self.$store.state.employeeDetails.avenue,
          alley: self.$store.state.employeeDetails.alley,
          houseNumber: self.$store.state.employeeDetails.houseNumber,
          notes: self.$store.state.employeeDetails.notes,
          cardNumber: self.$store.state.employeeDetails.cardNumber,
          martialStatus: 1,
          passportNumber: self.$store.state.employeeDetails.passportNumber,
          studyType: self.$store.state.employeeDetails.studyType,
          isRetired: "كلا",
          employeeId: self.$store.state.employeeDetails.idEmployee,
        };
        self.$http
          .post(`${self.$store.state.apiUrlEmployee}/addEmployeeInformation`, data, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((resImageUpdate) => {
            self.overlay = false;
            console.log(resImageUpdate);
            self.initialData();
            this.$toasted.success("تم رفع خزن البيانات");
          })
          .catch((error) => {
            self.overlay = false;
            console.log(error);
            this.$toasted.error("حدث خطأ في رفع البيانات");
          });
      }
    },

    updateEmployee() {
      let self = this;
      self.overlay = true;
      let contract = new Date(self.$store.state.employeeDetails.contractDate);

      self.$store.state.employeeDetails.contractDate = `${contract.getFullYear()}-${contract.getMonth() + 1}-${contract.getDate()}`;
      self.$store.state.employeeDetails.dob = self.$store.state.employeeDetails.dobFormat;

      self.$http
        .put(`${self.$store.state.apiUrlEmployee}/employee/${self.$store.state.employeeDetails.idEmployee}`, self.$store.state.employeeDetails, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((resImageUpdate) => {
          self.overlay = false;
          console.log(resImageUpdate);
          self.initialData();
          this.$toasted.success("تم رفع خزن البيانات");
        })
        .catch((error) => {
          self.overlay = false;
          console.log(error);
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },
    onButtonClick() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let self = this;
      this.selectedFile = e.target.files[0];

      this.isSelecting = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);

      self.$http
        .post(`${self.$store.state.apiUrlEmployee}/uploadAppImage`, formData)
        .then((res) => {
          let data = {
            imagePath: res.data.imagePath,
            employeeId: self.$store.state.employeeDetails.idEmployee,
            imageTypeId: 1,
          };
          if (self.$store.state.employeeDetails?.profileImage == null) {
            self.$http
              .post(`${self.$store.state.apiUrlEmployee}/addEmployeeImage`, data, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((resImage) => {
                self.overlay = false;
                console.log(resImage);
                self.initialData();
                this.$toasted.success("تم رفع خزن البيانات");
              })
              .catch((error) => {
                self.overlay = false;
                console.log(error);
                this.$toasted.error("حدث خطأ في رفع الصورة");
              });
          } else {
            self.$http
              .put(`${self.$store.state.apiUrlEmployee}/employeeImage/${self.$store.state.employeeDetails.idEmployeeImage}`, data, {
                headers: { Authorization: `Bearer ${self.$store.state.user}` },
              })
              .then((resImageUpdate) => {
                self.overlay = false;
                console.log(resImageUpdate);
                self.initialData();
                this.$toasted.success("تم رفع خزن البيانات");
              })
              .catch((error) => {
                self.overlay = false;
                console.log(error);
                this.$toasted.error("حدث خطأ في رفع الصورة");
              });
          }
        })
        .catch((error) => {
          self.overlay = false;
          console.log(error);
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },
    removeUpdate() {
      let self = this;
      self.update = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-div {
  width: 300px;
  height: 300px;
  background: rgb(121, 121, 121);
  margin: 10px auto;
  border-radius: 150px;
}
</style>
