<template>
  <v-app id="inspire">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-system-bar app color="primary" v-if="$store.state.user">
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar>

    <v-navigation-drawer v-model="drawer" right app v-if="!$vuetify.breakpoint.xs && $store.state.user">
      <v-sheet color="stepper" class="pa-4" style="text-align: center">
        <v-avatar class="mb-4" color="white darken-1" size="100">
          <span v-if="$store.state.employeeDetails?.profileImage" style="height: 100px; width: 100px">
            <img :src="`${$store.state.apiUrlEmployee}/${$store.state.employeeDetails.profileImage}`" alt="الصورة الشخصية" />
          </span>
          <span style="width: 100px" v-else>
            <img :src="photoPath" alt="الصورة الشخصية" />
          </span>
        </v-avatar>

        <div>{{ $store.state.userData.mail }}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-sheet elevation="0">
        <v-list>
          <v-subheader>القائمة الرئيسية</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary" mandatory>
            <v-list-item v-for="(item, i) in items" :key="i" @click="$router.push(`${item.router}`).catch(() => {})">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block depressed color="error" @click="logOut"> تسجيل خروج </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" flat v-if="$vuetify.breakpoint.xs && $store.state.user">
      <v-btn v-for="(link, i) in itemsPhone" :key="i" text @click="$router.push(`${link.router}`).catch(() => {})">
        <span style="color: #ffffff; font-size: 12px">{{ link.text }}</span>
      </v-btn>
      <v-menu top close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" text>
            <v-icon>mdi-arrow-down</v-icon>
            الاشعارات
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="$router.push(`/`).catch(() => {})">اشعارات القسم</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push(`/personal`).catch(() => {})">الاشعارات الشخصية</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-icon color="error" left @click="logOut"> mdi-logout</v-icon>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    selectedItem: 0,
    overlay: false,
    photoPath: "",
    items: [
      // { text: "الرئيسية", icon: "mdi-pill" },
      { text: "اشعارات القسم", icon: "mdi-bell", router: "/" },
      { text: "الاشعارات الشخصية", icon: "mdi-bell", router: "/personal" },
      { text: "الملف الشخصي", icon: "mdi-account-circle", router: "/profile" },
    ],
    itemsPhone: [
      // { text: "الرئيسية", icon: "mdi-pill" },

      { text: "الملف الشخصي", icon: "mdi-account-circle", router: "/profile" },
    ],
  }),
  created() {
    let self = this;

    this.$store.dispatch("getUserDate").then(() => {
      if (this.$store.state.user) {
        this.overlay = true;
        console.log(this.$store.state.userData);
        this.$http
          .get(`${self.$store.state.apiUrlEmployee}/employee/${this.$store.state.userData.idEmployee}`, {
            headers: {
              Authorization: `Beraer ${this.$store.state.user}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.overlay = false;
            self.$store.state.employeeDetails = res.data;
            self.photoPath = require("./assets/logo3.png");
            this.$router.push("/").catch(() => {});
          })
          .catch((e) => {
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
          });
      } else {
        this.$router.push("/login").catch((e) => {});
      }
    });
  },
  methods: {
    logOut() {
      localStorage.removeItem("employeeCredentials");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");
</style>
