import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrlEmployee: "http://5.5.5.10:4320/api",
    // apiUrlEmployee: "http://localhost:4320/api",
    // apiUrlRegister: "http://5.5.5.10:4300/api",
    // apiUrl: "http://5.5.5.10:5100/api",

    //https://tuiq.in

    // apiUrlLocal: "http://localhost:4320/api",
    apiUrl: "https://archive.janat-albelad.com/api",
    apiUrlEmployee: "https://employee.janat-albelad.com/api",
    apiUrlRegister: "https://student.janat-albelad.com/api",
    selectedYear: 0,
    employeeDetails: {},
    user: null,
    userData: null,
    employee: null,
    provinces: null,
    academicTitles: null,
    certificates: null,
    contractTypes: null,
    roles: null,
    subjects: [],
    sections: [],
    genders: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],
    oldArchiveTypes: [
      { id: 1, name: "صادر" },
      { id: 2, name: "وارد" },
    ],
    counts: null,
    insideOriginalArray: [],
  },
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("employeeCredentials");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
