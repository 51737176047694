<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="white" elevation="0" style="border-radius: 20px !important">
      <v-card-title class="warning-color" style="border-radius: 20px !important">
        الاوامر الادارية
        <v-spacer></v-spacer>

        <v-text-field rounded v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        <div style="width: 20px"></div>
        <!-- <AddIncome @reloadTask="initialData()" :resources="resources" :currencies="currencies" /> -->
      </v-card-title>
      <v-data-table :headers="headers" :items="items" :items-per-page="15" class="table-content table-warning-color">
        <template v-slot:[`item.index`]="{ index }">
          <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="dialog = false">
              <DeleteIncome :dataToDelete="item" @reloadTask="initialData()" />
            </v-btn>
          </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
    search: "",
    items: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "من", value: "incomeAmount" },
      { text: "التاريخ", value: "incomeDateFormat" },
      { text: "التفاصيل", value: "incomeNote" },
      //   { text: "الاجراءات", value: "actions" },
    ],
  }),
  // created() {
  //   this.initialData();
  // },
  // methods: {
  //   initialData() {
  //     let self = this;

  //     self.overlay = true;

  //     self.$http
  //       .all([
  //         self.$http.get(`${self.$store.state.apiUrl}/generationIncomes`, {
  //           headers: { Authorization: `Bearer ${self.$store.state.user}` },
  //         }),
  //         self.$http.get(`${self.$store.state.apiUrl}/currencies`, {
  //           headers: { Authorization: `Bearer ${self.$store.state.user}` },
  //         }),
  //         self.$http.get(`${self.$store.state.apiUrl}/generationResources`, {
  //           headers: { Authorization: `Bearer ${self.$store.state.user}` },
  //         }),
  //       ])
  //       .then(
  //         self.$http.spread((expenses, currencies, resources) => {
  //           self.expenses = expenses.data;
  //           self.currencies = currencies.data;
  //           self.resources = resources.data;

  //           self.overlay = false;
  //         })
  //       )
  //       .catch((error) => {
  //         self.overlay = false;
  //         console.log(error);
  //       });
  //   },
  // },
  // computed: {
  //   ItemsFilter() {
  //     if (this.search) {
  //       return this.expenses.filter((item) => {
  //         return this.search
  //           .toLowerCase()
  //           .split(" ")
  //           .every((v) => item.sectionName.toLowerCase().includes(v));
  //       });
  //     } else {
  //       return this.expenses;
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
